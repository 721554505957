import React, { FC } from 'react';
import classNames from 'classnames';
import { Box, BoxProps, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { InlineTextButton } from '../../../components';
import { defaultTreetStyles } from '../../../shopConfig/config';
import css from './TreetShopTopbar.module.css';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../hooks/useRouteConfiguration';
import {
  getSelectedCategories,
  TREET_SHOP_CATEGORIES_INFO,
  TreetShopCategory,
} from '../TreetShopLandingPage.utils';

interface TreetShopCategoriesProps {
  categories: TreetShopCategory[];
  selectedCategoryClassName: string;
  wrapperBoxOverrides?: Partial<BoxProps>;
}

export const TreetShopCategories: FC<TreetShopCategoriesProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const routes = useRouteConfiguration();

  const selectedCategories = getSelectedCategories(location);

  const { categories, selectedCategoryClassName, wrapperBoxOverrides = {} } = props;

  const removeCategoriesFromQuery = () => {
    history.push(createResourceLocatorString('TreetShopLandingPage', routes, {}));
  };

  const setCategory = (category: TreetShopCategory) => {
    history.push(createResourceLocatorString('TreetShopLandingPage', routes, {}, { category }));
  };

  return (
    <>
      {categories.map((category: TreetShopCategory) => {
        const config = TREET_SHOP_CATEGORIES_INFO[category];
        const { hidden, label } = config;
        if (!config || hidden) return null;
        const isSelectedCategory = selectedCategories.includes(category);
        return (
          <Box
            className={classNames({ [selectedCategoryClassName]: isSelectedCategory })}
            key={category}
            display="flex"
            alignItems="center"
            px={2}
            {...wrapperBoxOverrides}
          >
            <InlineTextButton
              className={css.categoryButton}
              onClick={() => {
                if (isSelectedCategory) {
                  removeCategoriesFromQuery();
                } else {
                  setCategory(category);
                }
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: isSelectedCategory ? 'white' : defaultTreetStyles.teal100,
                  fontWeight: 'bold',
                }}
              >
                {label}
              </Typography>
            </InlineTextButton>
          </Box>
        );
      })}
    </>
  );
};
