import { Form as FinalForm } from 'react-final-form';
import { Box, DialogActions, DialogContent } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
import { Button, DialogWithCloseButton, FieldTextInput } from '../../components';
import { ButtonVariant } from '../../components/Button/Button';
import { required } from '../../util/validators';
import { referBrand, TreetShopLandingPageState } from './TreetShopLandingPage.duck';
import { RequestStatus } from '../../types/requestStatus';

interface ReferBrandModalProps {
  open: boolean;
  onClose: () => void;
}

const ReferBrandModal: FC<ReferBrandModalProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { referBrandStatus } = useSelector<any>(
    (state) => state.TreetShopLandingPage
  ) as TreetShopLandingPageState;

  const inProgress = referBrandStatus === RequestStatus.Pending;

  const onSubmit = async (values: any) => {
    const { brand, notes } = values;

    await dispatch(referBrand({ brand, notes }));
    onClose();
    enqueueSnackbar('Submitted! Thank you.', {
      variant: 'success',
      transitionDuration: { enter: 800, exit: 500 },
    });
  };

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={(fieldRenderProps) => {
        const { handleSubmit } = fieldRenderProps;
        return (
          <form id="referBrandForm" onSubmit={handleSubmit}>
            <DialogWithCloseButton
              scroll="paper"
              open={open}
              onClose={onClose}
              titleText="Refer a Brand to Treet"
              fullWidth
              maxWidth="sm"
            >
              <DialogContent dividers>
                <Box py={2}>
                  <FieldTextInput
                    name="brand"
                    id="brand"
                    label="Brand Name*"
                    validate={required('Please enter a brand name')}
                  />
                  <Box mt={3}>
                    <FieldTextInput
                      name="notes"
                      id="notes"
                      label="Anything else we should know?"
                      placeholder="e.g. Why do you think they will be a good fit for Treet?"
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  form="referBrandForm"
                  disabled={inProgress}
                  inProgress={inProgress}
                  variant={ButtonVariant.Primary}
                >
                  Submit
                </Button>
              </DialogActions>
            </DialogWithCloseButton>
          </form>
        );
      }}
    />
  );
};

export default ReferBrandModal;
