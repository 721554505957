import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TreetShopCategories } from './TreetShopCategories';

import css from './TreetShopTopbar.module.css';
import { TreetShopCategory } from '../TreetShopLandingPage.utils';

interface TreetShopTopbarMobileMenuProps {
  categories: TreetShopCategory[];
}
const TreetShopTopbarMobileMenu: FC<TreetShopTopbarMobileMenuProps> = (props) => {
  const { categories } = props;

  return (
    <Box>
      <Box display="flex" pl={4} pt={3}>
        <Typography>
          <b>categories</b>
        </Typography>
      </Box>
      <TreetShopCategories
        categories={categories}
        wrapperBoxOverrides={{ ml: 3, mt: 1.5, pl: 1 }}
        selectedCategoryClassName={css.selectedCategoryMobileMenu}
      />
    </Box>
  );
};

export default TreetShopTopbarMobileMenu;
