import { Box, Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isEmpty, isEqual, startCase } from 'lodash';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from '../../components';
import TreetShopTopbar from './TreetShopTopbar/TreetShopTopbar';
import TreetShopGridItem from './TreetShopGridItem';
import { TreetShopLandingPageState } from './TreetShopLandingPage.duck';
import TreetShopFooter from './TreetShopFooter';
import { useIsMobile } from '../../hooks/useIsMobile';
import { TreetShopItem } from '../../types/contentful/treetShop';
import { getUrlSearchParams } from '../../util/urlHelpers';
import {
  getSelectedCategories,
  SPECIAL_CATEGORY_CONFIGS,
  TREET_SHOP_CATEGORIES_INFO,
  TreetShopCategory,
} from './TreetShopLandingPage.utils';
import { defaultTreetStyles } from '../../shopConfig/config';

interface BreadcrumbsProps {
  selectedCategories?: TreetShopCategory[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { selectedCategories } = props;
  let titleText = null;

  if (!selectedCategories?.length) {
    return null;
  }

  if (selectedCategories?.length === 1) {
    titleText = startCase(TREET_SHOP_CATEGORIES_INFO[selectedCategories[0]]?.label);
  } else {
    titleText = SPECIAL_CATEGORY_CONFIGS.find(({ categories }) =>
      isEqual(categories, selectedCategories)
    )?.title;
  }

  if (!titleText) return null;

  return (
    <Box display="flex" flexDirection="row" mb={3} sx={{ fontWeight: 'bold' }}>
      <NamedLink name="TreetShopLandingPage">
        <Typography
          variant="body1"
          style={{
            color: defaultTreetStyles.teal80,
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Treet Shop
        </Typography>
      </NamedLink>
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        &nbsp;/ {titleText}
      </Typography>
    </Box>
  );
};

const TreetShopLandingPage: FC = () => {
  // Change url without rerendering page
  if (typeof window !== 'undefined') {
    const searchParams = getUrlSearchParams();
    window.history.replaceState(null, '', `/?${searchParams.toString()}`);
  }

  const [isStickyFooter, setIsStickyFooter] = useState(false);
  const location = useLocation();

  const schemaTitle = 'Treet Shop';
  const schemaDescription =
    'A directory for finding brands that use Treet for buying and selling pre-loved items';

  const isMobile = useIsMobile();

  const { treetShopConfig } = useSelector<any>(
    (state) => state.TreetShopLandingPage
  ) as TreetShopLandingPageState;

  let treetShopItems = treetShopConfig?.itemsCollection?.items as TreetShopItem[] | undefined;

  const selectedCategories = getSelectedCategories(location);

  if (!isEmpty(selectedCategories) && treetShopItems) {
    treetShopItems = treetShopItems.filter((item) => {
      const categories = item.categories?.map((c) =>
        c.toLowerCase().replace('-', '').replace(' ', '')
      ) as TreetShopCategory[] | undefined;
      return selectedCategories.every((cat) => categories?.includes(cat));
    });
  }

  const sharingImageUrl = treetShopConfig?.sharingImage?.url;

  useEffect(() => {
    if (!isMobile) {
      setIsStickyFooter(true);
    } else {
      setIsStickyFooter(false);
    }
  }, [isMobile]);

  return (
    <Page
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: sharingImageUrl, width: 1200, height: 630 }]}
      twitterImages={[{ url: sharingImageUrl, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [sharingImageUrl],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TreetShopTopbar />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <Box display="flex" justifyContent="center">
            <Box
              maxWidth="1200px"
              mx={{ xs: 4, sm: 5, md: 10 }}
              mt={4}
              // Need this so that this box rerenders when the tab changes and the margin can be rerendered
              key={isStickyFooter.toString()}
              mb={isStickyFooter ? { xs: 35, md: 25 } : { xs: 4 }}
            >
              <Breadcrumbs selectedCategories={selectedCategories} />
              <Grid container spacing={isMobile ? 3 : 5}>
                {treetShopItems?.map((item) => (
                  <TreetShopGridItem key={item.shopConfig?.shopId} item={item} />
                ))}
              </Grid>
            </Box>
          </Box>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <TreetShopFooter isSticky={isStickyFooter} onClose={() => setIsStickyFooter(false)} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};
export default TreetShopLandingPage;
