import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import IconTreetShopLogo from '../../../components/Icons/IconTreetShopLogo/IconTreetShopLogo';
import { TreetShopCategories } from './TreetShopCategories';
import { defaultTreetStyles } from '../../../shopConfig/config';
import NamedLink from '../../../components/NamedLink/NamedLink';

import css from './TreetShopTopbar.module.css';
import { TreetShopCategory } from '../TreetShopLandingPage.utils';

interface TreetShopTopbarMobileProps {
  categories: TreetShopCategory[];
}

const TreetShopTopbarDesktop: FC<TreetShopTopbarMobileProps> = (props) => {
  const { categories } = props;

  return (
    <Box
      className={css.desktopRoot}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
    >
      <Box display="flex" flexDirection="column">
        <NamedLink name="TreetShopLandingPage">
          <IconTreetShopLogo className={css.shopIcon} />
        </NamedLink>
        <Typography
          style={{
            color: defaultTreetStyles.teal80,
            fontSize: '11px',
            fontWeight: 600,
            marginTop: '-6px',
            marginLeft: '22px',
          }}
          variant="body2"
        >
          <b>your home for brand-certified resale</b>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <TreetShopCategories
          categories={categories}
          wrapperBoxOverrides={{ m: 0.5, p: 2, justifyContent: 'center' }}
          selectedCategoryClassName={css.selectedCategoryDesktop}
        />
      </Box>
    </Box>
  );
};

export default TreetShopTopbarDesktop;
