import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIsMobile } from '../../../hooks/useIsMobile';
import Modal from '../../../components/Modal/Modal';
import TreetShopTopbarDesktop from './TreetShopTopbarDesktop';
import TreetShopTopbarMobile from './TreetShopTopbarMobile';
import { ModalParams, parse } from '../../../util/urlHelpers';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import TreetShopTopbarMobileMenu from './TreetShopTopbarMobileMenu';
import { TreetShopCategory } from '../TreetShopLandingPage.utils';
import { redirectToURLWithoutModalState } from '../../../util/window';

const TreetShopTopbar: FC = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { mobilemenu } = parse(location.search);
  const isMobileMenuOpen = mobilemenu === 'open';
  const handleMobileMenuClose = () => {
    redirectToURLWithoutModalState(history, location, ModalParams.MobileMenu);
  };

  const categories = [TreetShopCategory.Women, TreetShopCategory.Men, TreetShopCategory.Kids];

  return (
    <>
      {isMobile ? <TreetShopTopbarMobile /> : <TreetShopTopbarDesktop categories={categories} />}
      {isMobile && (
        <Modal
          id="TreetShopTopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={(componentId: string, disableScrolling: boolean) =>
            dispatch(manageDisableScrolling(componentId, disableScrolling))
          }
        >
          <TreetShopTopbarMobileMenu categories={categories} />
        </Modal>
      )}
    </>
  );
};

export default TreetShopTopbar;
