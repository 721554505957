import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button } from '../../../components';
import { defaultTreetStyles } from '../../../shopConfig/config';
import { redirectToURLWithModalState } from '../../../util/window';
import { ButtonVariant } from '../../../components/Button/Button';
import IconHamburgerMenu from '../../../components/Icons/IconHamburgerMenu/IconHamburgerMenu';
import IconHamburgerMenuFilled from '../../../components/Icons/IconHamburgerMenuFilled/IconHamburgerMenuFilled';
import IconTreetShopLogo from '../../../components/Icons/IconTreetShopLogo/IconTreetShopLogo';
import NamedLink from '../../../components/NamedLink/NamedLink';

import css from './TreetShopTopbar.module.css';
import { ModalParams } from '../../../util/urlHelpers';
import { getSelectedCategories } from '../TreetShopLandingPage.utils';

const TreetShopTopbarMobile: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const selectedCategories = getSelectedCategories(location);

  const handleMobileMenuOpen = () => {
    redirectToURLWithModalState(history, location, ModalParams.MobileMenu);
  };

  return (
    <Box
      display="flex"
      className={css.mobileRoot}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flex={1} pl={1}>
        <Button
          rootClassName={css.menuIcon}
          onClick={handleMobileMenuOpen}
          title="Open menu"
          variant={ButtonVariant.Custom}
        >
          {!isEmpty(selectedCategories) ? <IconHamburgerMenuFilled /> : <IconHamburgerMenu />}
        </Button>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <NamedLink name="TreetShopLandingPage">
          <IconTreetShopLogo className={css.shopIcon} />
        </NamedLink>
        <Typography
          style={{
            color: defaultTreetStyles.teal80,
            fontSize: '9px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            marginTop: '-8px',
          }}
          variant="body2"
        >
          <b>your home for brand-certified resale</b>
        </Typography>
      </Box>
      {/* Placeholder Box for centering IconTreetShopLogo. TODO replace with shopping bag */}
      <Box display="flex" flex={1} />
    </Box>
  );
};

export default TreetShopTopbarMobile;
