import { Box, Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getShopsCanonicalRootUrl } from '../../shopConfig/configHelper';
import { useShopByTreetIdLazyQuery } from '../../types/apollo/generated/types.generated';
import { TreetShopItem } from '../../types/contentful/treetShop';
import { Referrer } from '../../util/sessionHelpers/referrerSessionHelpers';
import css from './TreetShopLandingPage.module.css';
import { hasValidFiltersApplied } from './TreetShopLandingPage.utils';

interface ItemDescriptionProps {
  item: TreetShopItem;
}

const ItemDescription: FC<ItemDescriptionProps> = (props: ItemDescriptionProps) => {
  const { item } = props;

  const logoUrl = item.shopConfig?.images?.shopLogo?.url;
  const treetShopName = item.shopConfig?.treetShopName;

  return (
    <Grid container>
      <Grid item xs={8} spacing={1}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {treetShopName}
          </Typography>
          <Typography variant="body2">{item.tagline}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={4} justifyContent="flex-end">
        {logoUrl && <img src={logoUrl} alt={`${treetShopName} Logo`} className={css.shopLogo} />}
      </Grid>
    </Grid>
  );
};

interface TreetShopGridItemProps {
  item: TreetShopItem;
}

enum TreetShopGridItemSize {
  Regular = 'Regular',
  Large = 'Large',
}

const TreetShopGridItem: FC<TreetShopGridItemProps> = (props: TreetShopGridItemProps) => {
  const { item } = props;

  const location = useLocation();

  const treetId = item.shopConfig?.shopId;

  const [queryShopByTreetId, { loading: isQueryShopsLoading, data: queryShopsResponse }] =
    useShopByTreetIdLazyQuery();

  useEffect(() => {
    if (treetId) {
      queryShopByTreetId({ variables: { treetId } });
    }
  }, [treetId]);

  if (isQueryShopsLoading || !queryShopsResponse) return null;

  const hasFiltersApplied = hasValidFiltersApplied(location);
  const imageUrl = item.image?.url || item.shopConfig?.images?.missionImage?.url;
  const isLarge = item.size === TreetShopGridItemSize.Large;
  const shopName = item.shopConfig?.shopName;

  const { shop } = queryShopsResponse;
  const shopCanonicalRootUrl = getShopsCanonicalRootUrl(shop?.canonicalRootUrl, treetId);
  const rootUrl = shopCanonicalRootUrl?.concat(`?referrer=${Referrer.TreetShop}`);

  const squareRatioPadding = '100%';
  const twoToThreeRatioPadding = '66%';
  const shouldShowLargeCard = !hasFiltersApplied && isLarge;

  return (
    <Grid
      item
      xs={shouldShowLargeCard ? 12 : 6}
      md={shouldShowLargeCard ? 6 : 3}
      className={css.gridItem}
    >
      <a href={rootUrl || undefined}>
        <Box display="flex" flexDirection="column">
          <Box position="relative" width="100%">
            <Box pb={shouldShowLargeCard ? twoToThreeRatioPadding : squareRatioPadding}>
              {imageUrl && <img src={imageUrl} alt={`${shopName}`} className={css.shopImage} />}
            </Box>
          </Box>
          <Box mt={1}>
            <ItemDescription item={item} />
          </Box>
        </Box>
      </a>
    </Grid>
  );
};

export default TreetShopGridItem;
