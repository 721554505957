import { Location } from 'history';
import { parse } from '../../util/urlHelpers';

// lower cased values are used for query params
export enum TreetShopCategory {
  Women = 'women',
  Men = 'men',
  Kids = 'kids',
  Shoes = 'shoes',
  Accessories = 'accessories',
  Athleisure = 'athleisure',
  WomenOwned = 'womenowned',
  Sustainable = 'sustainable',
  Home = 'home',
  Canada = 'canada',
  Australia = 'australia',
  PlusSize = 'plussize',
  New = 'new',
}

export const TREET_SHOP_CATEGORIES_INFO: {
  [key in TreetShopCategory]: {
    key: TreetShopCategory;
    label: string;
    hidden?: boolean;
  };
} = {
  [TreetShopCategory.Women]: { key: TreetShopCategory.Women, label: 'women' },
  [TreetShopCategory.Men]: { key: TreetShopCategory.Men, label: 'men' },
  [TreetShopCategory.Kids]: { key: TreetShopCategory.Kids, label: 'kids' },
  [TreetShopCategory.Shoes]: { key: TreetShopCategory.Shoes, label: 'shoes', hidden: true },
  [TreetShopCategory.Accessories]: {
    key: TreetShopCategory.Accessories,
    label: 'accessories',
    hidden: true,
  },
  [TreetShopCategory.Athleisure]: {
    key: TreetShopCategory.Athleisure,
    label: 'athleisure',
    hidden: true,
  },
  [TreetShopCategory.WomenOwned]: {
    key: TreetShopCategory.WomenOwned,
    label: 'women-owned',
    hidden: true,
  },
  [TreetShopCategory.Sustainable]: {
    key: TreetShopCategory.Sustainable,
    label: 'sustainable',
    hidden: true,
  },
  [TreetShopCategory.Home]: { key: TreetShopCategory.Home, label: 'home', hidden: true },
  [TreetShopCategory.Canada]: {
    key: TreetShopCategory.Canada,
    label: 'canada',
    hidden: true,
  },
  [TreetShopCategory.Australia]: {
    key: TreetShopCategory.Australia,
    label: 'australia',
    hidden: true,
  },
  [TreetShopCategory.PlusSize]: {
    key: TreetShopCategory.PlusSize,
    label: 'plus-size',
    hidden: true,
  },
  [TreetShopCategory.New]: { key: TreetShopCategory.New, label: 'new', hidden: true },
};

// Used for emails when we want to direct users to a specific combined category
export const SPECIAL_CATEGORY_CONFIGS = [
  {
    title: 'Women’s Shoes',
    categories: [TreetShopCategory.Women, TreetShopCategory.Shoes],
  },
];

enum FilterType {
  Category = 'category',
}

export const getSelectedCategories = (location: Location) => {
  const { [FilterType.Category]: categoryQueryParam } = parse(location?.search);

  const categories = categoryQueryParam?.toLowerCase()?.split(',') || [];

  return Object.values(TreetShopCategory).filter((validCategory) =>
    categories.includes(validCategory)
  );
};

export const hasValidFiltersApplied = (location: Location) => {
  const categories = getSelectedCategories(location);
  return categories.length > 0;
};
